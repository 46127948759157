import { useAppSelector } from "@src/ducks/ducksHook";
import { selectPalmsPointBalance } from "@src/ducks/slices/user.slice";
import numeral from "numeral";
import React from "react";
import { Text } from "react-native";
import useStyles from "./styles.css";

const CoinCounter = ({ baseHeight }: any) => {
  const styles = useStyles();
  const ppbalance = useAppSelector(selectPalmsPointBalance);

  return (
    <Text style={[styles.goldText, { fontSize: baseHeight * 0.13}]}
    >{numeral(ppbalance).format("0,000.00")}</Text>
  );
};

export default CoinCounter;
