import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { useDimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedUserCoins } from "@src/ducks/slices/user.slice";
import numeral from "numeral";
import React from "react";
import { Image, View } from "react-native";
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { CoinContext } from "../../main/CoinProvider";
import ButtonSwitch from "./button-switch";
import ItemGold from "./item-gold";
import ItemShop from "./item-shop";
import useStyles from "./styles.css";

const GoldDropdown = () => {
  const styles = useStyles();
  const animated = useSharedValue(0);
  const navigation = useNavigation<any>();
  const balance  = useAppSelector(selectedUserCoins);
  const { currency } = React.useContext(CoinContext);
  const [currentBG, setCurrentBG] = React.useState(0);
  const [currentGWZ, setCurrentGWZ] = React.useState(0);
  const { baseWidth, hudHeight } = useDimensionContext();

  const onPress = () => {
    if(animated.value === 0){
      animated.value = withTiming(1, { duration: 500});
    }else{
      animated.value = withTiming(0, { duration: 500});
    }
  };

  const containerStyle = useAnimatedStyle(() => {
    const height = interpolate(
      animated.value,
      [0, 1],
      [hudHeight * 0.21, hudHeight],
      Extrapolation.CLAMP
    );

    return {
      overflow: "hidden",
      position: "absolute",
      width: baseWidth * 0.12, 
      height: height,
      top: hudHeight * 0.4,
      left: hudHeight * 0.2,
    };
  }, [baseWidth, hudHeight]);

  const animateStyle = useAnimatedStyle(() => {
    const translateY = interpolate(
      animated.value,
      [0, 1],
      [-hudHeight * 0.82, 0],
      Extrapolation.CLAMP
    );

    return {
      transform: [{translateY}],
      width: baseWidth * 0.12, 
      height: hudHeight,
    };
  }, [baseWidth, hudHeight]);

  const bodyStyle = useAnimatedStyle(() => {
    return {
      opacity: animated.value
    };
  }, []);

  React.useEffect(() => {
    setCurrentBG(balance["GOLD BONUS"]);
    setCurrentGWZ(balance["GOLD"]);
  }, [balance]);
  
  React.useEffect(() => {
    if (currency.bg > 0) {
      setCurrentBG((lastNumber) => {
        return lastNumber + currency.bg;
      });
    }else if (currency.gwz > 0 || currency.gwz <= -1) {
      setCurrentGWZ((lastNumber) => {
        return lastNumber + currency.gwz;
      });
    }
  }, [currency.bg, currency.gwz]);

  return (
    <Animated.View pointerEvents="box-none" style={containerStyle}>
      <Animated.View style={animateStyle}>
        <Image
          resizeMode="stretch"
          source={{uri: images["gold-dropdown-bg"]}}
          style={styles.i_dropdown}
        />
        <Animated.View style={[styles.v_body, bodyStyle]}>
          <View style={styles.v_divider} />
          <ItemGold
            source={images["gold-coin-2"]} 
            onPress={() => navigation.navigate("bonus-gold-info")}
            amount={numeral(currentBG).format("0,000.00")} />
          <View style={styles.v_divider2} />
          <ItemGold 
            source={images["gold-palm"]} 
            onPress={() => navigation.navigate("palms-gold-info")} 
            amount={numeral(currentGWZ).format("0,000.00")} />
          <View style={{height: "4.1%"}} />
          <ItemShop onCancel={onPress} animatedValue={animated}/>
          <View style={styles.v_divider2} />
        </Animated.View>
        <ButtonSwitch onPress={onPress} animated={animated} />
      </Animated.View>
    </Animated.View>
  );
};



export default GoldDropdown;


