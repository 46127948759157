import { useAppDispatch, useAppSelector } from "@src/ducks/ducksHook";
import { authActions, selectedAuthToken } from "@src/ducks/slices/auth.slice";
import { useCallback, useState } from "react";
import { CurrentApi } from "../request";

type PromiseFunction = () => Promise<any>;

interface FetchPostValue {
  loading: boolean;
  data: any;
  error: any;
  runRequest: (routes: string, params: any, path?: string) => void;
  runReset: () => void;
  runCallbackRequest: (
    arg1: PromiseFunction, 
    handleSuccess: (e: any) => void, 
    handleError: (e: any) => void) => void;
}

export const useFetchPost = (initialData = null) => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState(initialData);
  const token: any = useAppSelector(selectedAuthToken);
  const [loading, setLoading] = useState<boolean>(false);

  const runRequest = useCallback(
    async (routes: string, params: any, path: any = null) => {
      try {
        setLoading(true);
        CurrentApi.setToken(token);
        const result = await CurrentApi.post(`${routes}`, params);

        if(path === "palmsbet"){
          setData(result);
        }else {
          setData(result.data || true);
        }
      } catch (err: any) {
        if(err?.status === 401 && err?.error === "Unauthorized"){
          dispatch(authActions.logout());
          dispatch(authActions.setErrorMessage("Сесията е изтекла"));
        }
        const message = err?.error?.message ?? "Something went wrong";
        setError({ message });
      } finally {
        setLoading(false);
      }
    },
    [token, dispatch]
  );

  const runCallbackRequest = useCallback(
    async (promiseFunction: () => Promise<any>, handleSuccess: (e: any) => void, handleError: (e: any) => void) => {
      try {
        setLoading(true);
        setData(null);

        if (typeof promiseFunction !== "function") {
          handleError({ message: "Invalid argument" });
          return null; // or handle it accordingly
        }

        const response = await promiseFunction();

        setLoading(false);
        handleSuccess(response);
      } catch (err: any) {
        console.log("errerrerrerrerr", err);
        if(err?.status === 401 && err?.error === "Unauthorized"){
          dispatch(authActions.logout());
          dispatch(authActions.setErrorMessage("Сесията е изтекла"));
        }

        let message = err?.error?.message ?? err?.message ?? "Something went wrong";
        if(err?.code === "PPT003"){
          message = "Вашето месечно използване на точки за лоялност достигна лимита от 400 точки. Достъпът до нашите пакети с монети ще бъде възобновен на първия ден от следващия месец.";
        }else if(err?.code === "PPT001"){
          message = "Моля, обърнете внимание, че златните монети на Palms могат да бъдат осребрени само за златни билети в определения ден за теглене на лотарията";
        }

        handleError({message});
        setLoading(false);
      }
    },
    [dispatch]
  );

  const runReset = useCallback(() => {
    setLoading(false);
    setData(null);
    setError(null);
  },[]);

  return {
    loading,
    data,
    error,
    runRequest,
    runReset,
    runCallbackRequest
  } as FetchPostValue;
};
