import React, { useCallback, useState } from "react";
import ErrorMessage from "@src/components/modals/error-sideway";
import SuccessDialog from "@src/components/modals/success-top";
import { SwordPlayInit } from "@src/ducks/types";

interface ProviderProps {
  children: React.ReactElement;
}

interface ContextValue {
  smdata: SwordPlayInit;
  onErrorMessage: (value: string) => void;
  onSuccessMessage: () => void;
  onSetSMdata: (value: SwordPlayInit) => void;
}

export const GModeContext = React.createContext<ContextValue>(
  {} as ContextValue
);

const GModeProvider = ({ children }: ProviderProps) => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const onErrorMessage = useCallback(setError, [error]);
  const onSuccessMessage = useCallback(() => setSuccess(true), [success]);
  const [smdata, setSMdata] = useState<SwordPlayInit>({} as SwordPlayInit);

  const onSetSMdata = useCallback(setSMdata, [smdata]);

  return (
    <GModeContext.Provider value={{smdata, onErrorMessage, onSuccessMessage, onSetSMdata }}>
      {children}
      <ErrorMessage
        message={error}
        visible={error !== null}
        onClose={() => setError(null)}
      />
      <SuccessDialog message="" visible={success} onClose={() => setSuccess(false)} />
    </GModeContext.Provider>
  );
};

export default GModeProvider;
