import images from "@assets/images";
import { useAuthService } from "@src/ducks/hooks";
import { ImageButton06 } from "components/button";
import React from "react";
import { signOut } from "utils/api";
import { useFetchPost } from "utils/api/hooks";

const Logout = ({ baseWidth, baseHeight }: any) => {
  const {onLogout} = useAuthService();
  const { loading, runCallbackRequest } = useFetchPost();

  const handleLogout = () => {
    runCallbackRequest(
      () => signOut(),
      () => {
        onLogout();
      },
      () => {
        onLogout();
      }
    );
  };

  return (
    <ImageButton06 
      source={{uri: images["btn-big"]}}
      onPress={handleLogout} 
      label="Излез"
      isLoading={loading}
      style={{width: baseWidth * 0.531, height: baseHeight * 0.13}} />
  );
};

export default React.memo(Logout);
