import { useCallback } from "react";
import { useAppDispatch } from "../ducksHook";
import { soundActions } from "../slices/sound.slice";

type ServiceOperators = {
  bgsoundPlay: () => void;
  bgsoundPause: () => void;
  bgsoundEnable: () => void;
  bgsoundDisable: () => void;
  clickSoundToggle: () => void;
  claimSoundToggle: () => void;
  rewardSoundToggle: () => void;
};

const useSoundService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();

  return {
    bgsoundPlay: useCallback(() => dispatch(soundActions.bgsoundPlay()),
      [dispatch]),
    bgsoundPause: useCallback(() => dispatch(soundActions.bgsoundPause()),
      [dispatch]),
    bgsoundEnable: useCallback(() => dispatch(soundActions.bgsoundEnable()),
      [dispatch]),
    bgsoundDisable: useCallback(() => dispatch(soundActions.bgsoundDisable()),
      [dispatch]),
    clickSoundToggle: useCallback(() => dispatch(soundActions.clickSoundToggle()),
      [dispatch]),
    claimSoundToggle: useCallback(() => dispatch(soundActions.claimSoundToggle()),
      [dispatch]),
    rewardSoundToggle: useCallback(() => dispatch(soundActions.rewardSoundToggle()),
      [dispatch]),
  };
};

export default useSoundService;