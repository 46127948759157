const Categories = [
  {
    "name": "MY FAVOURITE",
    "hasGames": true,
    "source": "my-favorite",
    "activeKey": 0,
  },
  {
    "name": "SLOT",
    "hasGames": true,
    "source": "slots",
    "activeKey": 1,
  },
  {
    "name": "TABLE",
    "hasGames": true,
    "activeKey": 2,
    "source": "table"
  },
  {
    "name": "INSTANT",
    "hasGames": true,
    "activeKey": "3,5",
    "source": "instant"
  },
  {
    "name": "ACTION",
    "hasGames": true,
    "activeKey": 4,
    "source": "action"
  },
  {
    "name": "PUZZLES",
    "hasGames": true,
    "activeKey": 8,
    "source": "puzzle"
  },
  {
    "name": "ARCADE",
    "hasGames": true,
    "activeKey": "6,7",
    "source": "arcade"
  },
];

export default Categories;
