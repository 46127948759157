import { useRoute } from "@react-navigation/native";
import { useGModeService } from "@src/ducks/hooks";
import useSoundService from "@src/ducks/hooks/useSoundService";
import React, { useEffect } from "react";
import { View } from "react-native";
import GameView from "./GameView";
import Drawer from "./actionbutton";
import CoinsDialog from "./coins-dialog";
import GModeProvider from "./provider";
import useStyles from "./styles.css";

const GameMode = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const { bgsoundPause, bgsoundPlay } = useSoundService();
  const { onGModeRequest, onLeaveGModeRequest } = useGModeService();

  useEffect(() => {
    bgsoundPause();
    onGModeRequest(route.params.id);
    return () => {
      onLeaveGModeRequest();
      bgsoundPlay();
    };
  }, []);

  return (
    <GModeProvider>
      <View style={styles.container}>
        <GameView />
        <Drawer />
        <CoinsDialog />
      </View>
    </GModeProvider>
  );
};

export default React.memo(GameMode);
