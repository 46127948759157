import { CDN_URL } from "@env";
import { LandscapeTypes } from "./image-types/landscape-types";

const LandscapeAssets: LandscapeTypes = {
  "img_0": CDN_URL + "/image-assets/animation-assets/landscape/images/img_0.png",
  "img_1": CDN_URL + "/image-assets/animation-assets/landscape/images/img_1.png",
  "img_2": CDN_URL + "/image-assets/animation-assets/landscape/images/img_2.png",
  "img_3": CDN_URL + "/image-assets/animation-assets/landscape/images/img_3.png",
};

export default LandscapeAssets;
