import { colors } from "@src/theme";
import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      zIndex: 10,
      width: "100%",
      height: "100%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.translucent,
    },
    lottie_style: { position: "absolute", left: "-14%", width: "100%", height: "100%" },
  },
);

export default useStyles;
