import React from "react";
import { SvgProps } from "react-native-svg";

//SVGs
import BonusButton from "./bonus-button.svg";
import ButtonBack from "./button-back.svg";
import ButtonNext from "./button-next.svg";
import ButtonExit from "./button-exit.svg";
import Close from "./close.svg";
import FrameFootball from "./frame-football.svg";
import Inbox from "./inbox.svg";
import SignupBonus from "./signup-bonus.svg";
import Welcome from "./welcome.svg";

export interface IconProps extends SvgProps {
  name: "back" | "next" | "camera" | "document" | "addFill" | "button-next" | "button-back" | any;
  size?: number;
  stroke?: string;
  fill?: string;
  isActive?: boolean;
}

const SVGIcon = (props: IconProps) => {
  const { name, width, height } = props;
  switch (name) {
  case "frame-football":
    return <FrameFootball width={width || 235} height={height || 62} {...props} />;
  case "bonus-button":
    return <BonusButton width={width || 235} height={height || 62} {...props} />;
  case "inbox":
    return <Inbox width={width || 235} height={height || 62} {...props} />;
  case "close":
    return <Close width={width || 235} height={height || 62} {...props} />;
  case "welcome":
    return <Welcome width={width || 235} height={height || 62} {...props} />;
  case "signup-bonus":
    return <SignupBonus width={width || 235} height={height || 62} {...props} />;
  case "button-next":
    return <ButtonNext width={width || 63} height={height || 21} {...props} />;
  case "button-back":
    return <ButtonBack width={width || 63} height={height || 21} {...props} />;
  case "button-exit":
    return <ButtonExit width={width || 63} height={height || 21} {...props} />;
  default:
    return null;
  }
};

export default SVGIcon;
