import { useNavigation } from "@react-navigation/native";
import React from "react";
import { View } from "react-native";
import Settings from "screens/settings/Settings";
import { Scale } from "utils/animation";
import useOrientationListener from "utils/orientation-helper";
import useStyles from "./styles.css";

export default () => {
  const styles = useStyles();
  const navigation = useNavigation();
  const orientation = useOrientationListener();

  return (
    <View style={styles.container}>
      <Scale style={styles.container}>
        <Settings
          onBack={() => navigation.goBack()}
          isPortrait={orientation === "PORTRAIT"}
          style={orientation === "PORTRAIT" ? styles.v_settings_portrait : styles.v_settings}
        />
      </Scale>
    </View>
  );
};
