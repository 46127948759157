import { useCallback } from "react";
import { useAppDispatch } from "../ducksHook";
import { gmodeActions } from "../slices/gmode.slice";

// Types
import { GameMode, SwordPlayInit } from "../types";

type ServiceOperators = {
  onGModeRequest: (params: GameMode) => void;
  onSwordPlayBuyDiamonds: (params: SwordPlayInit) => void;
  onLeaveGModeRequest: () => void;
};

export const useGModeService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();

  return {
    onGModeRequest: useCallback(
      (params) => {
        dispatch(gmodeActions.gmodeRequest(params));
      },
      [dispatch]
    ),
    onSwordPlayBuyDiamonds: useCallback(
      (params) => {
        dispatch(gmodeActions.topupSwordPlayRequest(params));
      },
      [dispatch]
    ),
    onLeaveGModeRequest: useCallback(
      () => {
        dispatch(gmodeActions.gameModeLeave());
        dispatch(gmodeActions.missionRequest());
      },
      [dispatch]
    ),
  };
};

export default useGModeService;
