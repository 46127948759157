import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      zIndex: 2,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.translucent,
    },
    flex1: { flex: 1, zIndex: 4 },
    v_settings: {
      zIndex: 10,
      top: "7.5%",
      right: "34%",
      width: "32%",
      height: "85%",
      position: "absolute",
    },

    v_settings_portrait: {
      zIndex: 10,
      top: "7.5%",
      right: "20%",
      width: "60%",
      height: "40%",
      position: "absolute",
    },
  },
  {
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
