// DUCKS pattern
import { createAction, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@src/ducks/store";
import { ErrorValue, SignupInput } from "../types";

export interface SignupState {
  loading: boolean;
  signupSuccess: boolean;
  signupData: any;
  error: ErrorValue;
}

export const initialState: SignupState = {
  loading: false,
  signupSuccess: false,
  signupData: {},
  error: {} as ErrorValue,
};

// Slice
export const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    signupRequest: (state) => {
      state.loading = true;
      state.error = {} as ErrorValue;
    },
    signupSuccess: (state, action) => {
      state.signupSuccess = true;
      state.signupData = action.payload;
      state.error = {} as ErrorValue;
      state.loading = false;
    },
    signupFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

// Actions
export const signupActions = {
  signupRequest: createAction(
    `${signupSlice.name}/signupRequest`,
    (params: SignupInput) => ({
      payload: params,
    })
  ),
  signupSuccess: signupSlice.actions.signupSuccess,
  signupFailure: signupSlice.actions.signupFailure,
};

// Selectors
export const selectedSigningIn = (state: RootState) => state.signup.loading;
export const selectedSigningFailed = (state: RootState) => state.signup.error;
export const selectedSigningSuccess = (state: RootState) => state.signup.signupSuccess;
export const selectedSigningDataID= createSelector(
  (state: RootState) => state.signup.signupData,
  (session) => session.id,
);

// Reducer
export default signupSlice.reducer;
