import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import React from "react";
import { Image, View } from "react-native";
import GoldCoins from "./gold-coins";
import GoldDropdown from "./gold-dropdown";
import useStyles from "./styles.css";

const GoldBar = () => {
  const styles = useStyles();
  const { hudHeight } = React.useContext(DimensionContext);

  return (
    <View style={[styles.container, { width: hudHeight * 1.747, height: hudHeight * 0.5728 }]}>
      <View pointerEvents="none" style={styles.goldBar}>
        <Image
          resizeMode="stretch"
          style={styles.goldBar}
          source={{uri: images.gold_bar}}
        />
      </View>
      <GoldCoins baseHeight={hudHeight} />
      <GoldDropdown />
    </View>
  );
};

export default GoldBar;
