import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useErrorService } from "@src/ducks/hooks";
import { selectedAuthToken } from "@src/ducks/slices/auth.slice";
import { ImageButton06 } from "components/button";
import React from "react";
import { View } from "react-native";
import { runOnJS, SharedValue, useAnimatedReaction } from "react-native-reanimated";
import { checkingConversion } from "utils/api";
import { useFetchPost } from "utils/api/hooks";
import useStyles from "./styles.css";

type Props = {
  onCancel: () => void;
  animatedValue: SharedValue<number>;
}

const ItemShop = (props: Props) => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { onSetErrorMessage } = useErrorService();
  const [enabled, setEnabled] = React.useState(false);
  const accessToken = useAppSelector(selectedAuthToken);
  const { loading, runCallbackRequest } = useFetchPost();

  const handlePress = () => {
    if(enabled){
      runCallbackRequest(
        () => checkingConversion(accessToken),
        (response) => {
          if(response.isRedeemable){
            navigation.navigate("gold-conversion");
            props.onCancel();
          }else{
            onSetErrorMessage("Моля, обърнете внимание, че златните монети на Palms могат да бъдат осребрени само за златни билети в определения ден за теглене на лотарията");
          }
        },
        (error: any) => {
          if(error.message){
            console.log("Error generating token:", error); // Handle error
          }
        }
      );
    }
   
  };

  useAnimatedReaction(
    () => props.animatedValue.value,
    (value, previousValue) => {
      if (value !== previousValue) {
        runOnJS(setEnabled)(value === 1);
      }
    }
  );

  return (
    <View style={styles.v_container_shop}>
      <ImageButton06 
        scaleSize={1}
        onPress={handlePress} 
        isLoading={loading}
        disabled={!enabled}
        style={styles.v_item_shop}
        source={{uri: images["btn-convert"]}}
      />
    </View>
  );
};

export default ItemShop;
