import { CreateResponsiveStyle } from "rn-responsive-styles";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "50%",
      height: "80%",
      marginBottom: "2%",
      alignItems: "center",
      justifyContent: "center",
    },
    goldBar: {
      width: "100%",
      height: "100%",
      zIndex: 1
    },
    goldText: {
      zIndex: 2,
      right: "33%",
      marginTop: "9%",
      fontSize: 10,
      color: colors.white,
      position: "absolute",
      fontFamily: "Roboto-Medium",
    },
  },
);

export default useStyles;
