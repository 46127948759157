import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import { useLobbyService, useSettingsService } from "@src/ducks/hooks";
import { ImageButton } from "components/button";
import React from "react";

const Home = () => {
  const { hudHeight } = React.useContext(DimensionContext);
  const { onChangeScreen, onSwitchCategory } = useLobbyService();
  const { onChangeSettings, onChangeProfile }  = useSettingsService();

  const onHome = () => {
    onChangeScreen({ screen: "main" });
    onSwitchCategory(-1);
    onChangeSettings(false);
    onChangeProfile(false);
  };

  return (
    <ImageButton
      onPress={onHome}
      source={{uri: images.btn_home}}
      style={[{ width: hudHeight * 0.641, height: hudHeight * 0.5533 }]}
    />
  );
};

export default React.memo(Home);
