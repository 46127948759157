import { SagaIterator } from "@redux-saga/core";
import { Audio } from "expo-av";
import { call, select, takeEvery } from "redux-saga/effects";

// Slice
import { audio } from "@src/theme";
import { selectSoundEnabled, selectSoundPlaying, soundActions } from "../slices/sound.slice";

let bgSoundObject: any;
let clickSoundObject: any;
let claimSoundObject: any;
let rewardSoundObject: any;

function* playSoundSaga(): SagaIterator {
  try {
    const soundEnabled = yield select(selectSoundEnabled);
  
    if (soundEnabled) {
      if (!bgSoundObject) {
        bgSoundObject = new Audio.Sound();
        yield bgSoundObject.loadAsync(audio.whoosh, {
          isLooping: true,
        });
      }
      yield bgSoundObject.playAsync();
    }

    if(!clickSoundObject){
      clickSoundObject = new Audio.Sound();
      yield clickSoundObject.loadAsync(audio.click);
    }
    if(!claimSoundObject){
      claimSoundObject = new Audio.Sound();
      yield claimSoundObject.loadAsync(audio.claim);
    }
    if(!rewardSoundObject){
      rewardSoundObject = new Audio.Sound();
      yield rewardSoundObject.loadAsync(audio.reward_sound);
    }
  } catch (error: any) {
    console.log("playSoundSaga", error);
  }
}

function* pauseSoundSaga(): SagaIterator {
  if (bgSoundObject) {
    yield bgSoundObject.pauseAsync();
  }
}

function* enableSoundSaga(): SagaIterator {
  const soundPlaying = yield select(selectSoundPlaying);
  
  // If the sound was playing, resume playback
  if (soundPlaying) {
    yield call(playSoundSaga);
  }
}

function* disableSoundSaga(): SagaIterator {
  if (bgSoundObject) {
    yield bgSoundObject.pauseAsync();
  }
}
function* handleClickSound(): SagaIterator {
  if (clickSoundObject) {
    yield clickSoundObject.playAsync();
  }
}
function* handleClaimSound(): SagaIterator {
  if (claimSoundObject) {
    yield claimSoundObject.playAsync();
  }
}
function* handleRewardSound(): SagaIterator {
  if (rewardSoundObject) {
    yield rewardSoundObject.playAsync();
  }
}

// Watcher Saga
function* soundWatcherSaga(): SagaIterator {
  yield takeEvery(soundActions.bgsoundPlay.type, playSoundSaga);
  yield takeEvery(soundActions.bgsoundPause.type, pauseSoundSaga);
  yield takeEvery(soundActions.bgsoundEnable.type, enableSoundSaga);
  yield takeEvery(soundActions.bgsoundDisable.type, disableSoundSaga);
  yield takeEvery(soundActions.clickSoundToggle.type, handleClickSound);
  yield takeEvery(soundActions.claimSoundToggle.type, handleClaimSound);
  yield takeEvery(soundActions.rewardSoundToggle.type, handleRewardSound);
}

export default soundWatcherSaga;
