import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { ImageButton02 } from "components/button";
import React from "react";
import { Image } from "react-native";
import useStyles from "./styles.css";

const Email = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { hudHeight } = React.useContext(DimensionContext);

  return (
    <ImageButton02
      onPress={() => navigation.navigate("Inbox")}
      style={[styles.container, 
        { width:  hudHeight * 0.631, height: hudHeight * 0.4951, marginTop: hudHeight * 0.01 }]}
    >
      <Image
        source={{uri: images.btn_mail}}
        style={styles.size_egg}
        resizeMode="stretch"
      />
    </ImageButton02>
  );
};

export default Email;
