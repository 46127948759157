import images from "@assets/images";
import React from "react";
import { Image, View } from "react-native";
import LoginToPalms from "../login/login-to-palms";
import useStyles from "./styles.css";

const SignInOption = () => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
       <Image
        source={{uri: images["bg-splash"]}}
        style={[styles.i_background]}
        resizeMode="stretch"
      />
      <LoginToPalms />
    </View>
  );
};

export default SignInOption;
