import images from "@assets/images";
import SVGDrawer from "@assets/svg/side-drawer";
import { useDimensionContext } from "@src/DimensionProvider";
import { ImageButton05 } from "components/button";
import React from "react";
import { Image } from "react-native";

type Props = {
  onPress: () => void;
  item: any;
  source: any;
  isComingSoon: boolean;
  isActive?: boolean;
}

function DrawerItem(props: Props) {
  const { baseWidth } = useDimensionContext();
  
  const RenderComingSoon = React.useMemo(() => {    
    if(props.isComingSoon){
      return <Image source={{uri: images["coming-soon"]}} 
        style={{width: baseWidth * 0.04, height: baseWidth * 0.04, position: "absolute", top: "2%", right: "2%"}} 
        resizeMode="stretch" />;
    }

    return null;
  },[props.isComingSoon]);

  return (
    <ImageButton05 onPress={props.onPress} >
      <SVGDrawer name={props.source} width={baseWidth * 0.056} height={baseWidth * 0.056} isActive={props.isActive}/>
      {RenderComingSoon}
    </ImageButton05>
  );
}

export default DrawerItem;