import { StackActions, useNavigation, useRoute } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useErrorService, useLobbyService } from "@src/ducks/hooks";
import { selectedAuthToken } from "@src/ducks/slices/auth.slice";
import { selectPalmsPointBalance } from "@src/ducks/slices/user.slice";
import { colors } from "@src/theme";
import { Loading02 } from "components/loading";
import _ from "lodash";
import React from "react";
import { palmsPurchase } from "utils/api";
import { useFetchPost } from "utils/api/hooks";
import { useCoinContext } from "../home/main/CoinProvider";
import PurchaseConfirmation from "../popups/pop-notice/purchase-confirmation";

const PaymentGateway = () => {
  const route = useRoute<any>();
  const navigation = useNavigation<any>();
  const { onAdditionalCurrency } = useCoinContext();
  const { onSetErrorMessage } = useErrorService();
  const accessToken = useAppSelector(selectedAuthToken);
  const { updatePalmsPointBalance } = useLobbyService();
  const ppbalance = useAppSelector(selectPalmsPointBalance);
  const { loading, runCallbackRequest, runReset } = useFetchPost();

  const onPurchase = () => {
    const productId = route?.params?.id;
    const mailId = route?.params?.mailId;
    const palmsPoints = route?.params?.palmsPoints;
    if(palmsPoints && palmsPoints > ppbalance){
      navigation.navigate("insufficient-palms-point");
      return;
    }
    runCallbackRequest(
      () => palmsPurchase({ productId, mailId }, accessToken),
      (response) => {
        const silver = route?.params?.value;
        const goldBonus = route?.params?.goldBonus;
        navigation.dispatch(StackActions.replace("PaymentConfirm", {
          silver: silver,
          gold: goldBonus,
        }));
        onAdditionalCurrency({silver: silver, bg: goldBonus || 0, gwz: 0});
        updatePalmsPointBalance(response.palmsPointBalance);
        runReset();
      },
      (error: any) => {
        if(!_.isEmpty(error?.message)){
          runReset();
          onSetErrorMessage(error?.message);
        }
      }
    );
  };

  if(loading){
    return (
      <Loading02 
        color="white"
        style={{
          zIndex: 8,
          paddingTop: 0,
          width: "100%",
          height: "100%",
          position: "absolute",
          alignItem: "center", 
          justifyContent: "center", 
          backgroundColor: colors.translucent
        }} />
    );
  }

  return <PurchaseConfirmation onConfirm={onPurchase} />;
};

export default PaymentGateway;
