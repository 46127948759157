import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { ButtonProps } from "./types";

const Button02 = (props: ButtonProps) => {
  const { onPress, style, disabled, children } = props;

  return (
    <TouchableOpacity
      ref={props.ref}
      onPress={onPress}
      activeOpacity={0.8}
      disabled={disabled}
      style={[styles.buttonStyle, style]}
    >
      {children}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  buttonStyle: {
    alignItems: "center",
    justifyContent: "center",
  },
});

export default React.memo(Button02);
