import images from "@assets/images";
import { useNavigation, useRoute } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import React from "react";
import { Image, Pressable, Text, View } from "react-native";
import useStyles from "./styles.css";

const ComingSoon = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const navigation = useNavigation<any>();
  const { baseWidth } = React.useContext(DimensionContext);

  return (
    <View style={styles.container}>
      <Pressable style={styles.btnStyle} onPress={() => navigation.pop()} />
      <View
        pointerEvents="box-none"
        style={[
          styles.center_view,
          { width: baseWidth * 0.41, height: baseWidth * 0.09 },
        ]}
      >
        <Image
          style={styles.i_background}
          source={{uri: images["bg-notice-2"]}}
          resizeMode="stretch"
        />
        <Image
          style={{width: "22%", height: "160%", position: "absolute", right: "-8%", marginBottom: "8%", transform: [{rotateY: "180deg"}]}}
          source={{uri: images["referee_2"]}}
          resizeMode="stretch"
        />
        <View style={styles.v_access_denied}>
          <Image
            style={styles.i_access_denied_exclamation}
            source={{uri: images["exclamation"]}}
            resizeMode="stretch"
          />
          <Text style={[styles.t_access_level, {fontSize: baseWidth * 0.015, lineHeight: baseWidth * 0.019}]}>
            {route?.params?.name} GAMES ARE COMING SOON
          </Text>
        </View>
      </View>
    </View>
  );
};

export default ComingSoon;
