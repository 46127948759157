import React, { useState } from "react";
import TierInfoMore from "./TierInfoMore";
import TierLevelReward from "./TierLevelReward";
import { useRoute } from "@react-navigation/native";

export default () => {
  const route = useRoute<any>();
  const [viewmore, setViewmore] = useState(route?.params?.viewmore ?? false);

  return viewmore ? (
    <TierInfoMore />
  ) : (
    <TierLevelReward onPress={() => setViewmore(true)} />
  );
};
